<!--
 * @Descripttion: 标签动态输入框组
 * @version: 1.0
 * @program: django-vue-lyadmin
 * @Author: lybbn
 * @Date: 2023.11.27
-->
<template>
    <div>
        <el-row :gutter="20" style="margin-bottom: 20px;">
            <el-col :span="7" style="text-align: center;font-weight: bold;">标签名称</el-col>
            <el-col :span="17" style="text-align: center;font-weight: bold;">标签值</el-col>
        </el-row>
        <draggable :list="dataList" item-key="id" :animation="300"
            v-bind="{ group: 'optionsGroup', ghostClass: 'ghost', handle: '.drag-option' }"
            v-if="!!dataList && dataList.length > 0">
            <template #item="{ element: item, index: index }">
                <div style="margin-bottom: 5px;border: 1px solid var(--el-border-color);padding: 10px;">
                    <el-form :inline="false" :model="item" :rules="rules"
                        :ref="(el) => setTreeRef(el, 'rulesForm' + index)">
                        <el-row :gutter="20" style="margin-bottom: 10px;">
                            <el-col :span="1" style="display: flex;align-items: center;">
                                <div class="drag-option" style="cursor: move;margin-right: 10px;" v-if="dragSort">
                                    <el-icon size="20">
                                        <Rank />
                                    </el-icon>
                                </div>
                            </el-col>
                            <el-col :span="5"
                                style="border-right:1px solid var(--el-border-color);display:flex;align-items:center;">
                                <el-form-item prop="label" label="标签名：" style="width:100%;">
                                    <el-input v-model="item.label" :placeholder="placeholder1"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="17" style="display: flex;align-items: center;">
                                <div style="width: 60px;flex-shrink: 0;">标签值：</div>
                                <el-button type="primary" icon="Plus" :size="size" title="添加"
                                    @click="handleValueAdd(index)">{{
                                        buttonText }}</el-button>
                                <div style="margin-left: 10px;" class="drageltag">
                                    <draggable v-model="item.value" animation="200" item-key="id"
                                        v-bind="{ group: 'group_info', ghostClass: 'ghost', handle: '.drag-values' }">
                                        <template #item="{ element: nitem, index: nindex }">
                                            <el-tag class="groupitem" style="margin-right: 16px;margin-bottom: 16px;"
                                                type="warning" closable @close="handelDelGroupValue(index, nindex)">
                                                <template #default>
                                                    <div style="display: flex;align-items: center;">
                                                        <el-icon size="15" style="cursor: move;" class="drag-values">
                                                            <Menu />
                                                        </el-icon>
                                                        <el-form-item :prop="`value[${nindex}].value`" :rules="rules.value">
                                                            <el-input v-model="nitem.value" size="small" style="width:150px"
                                                                placeholder="请输入"
                                                                @change="(val) => handleInputConfirm(val, index, nindex)"></el-input>
                                                        </el-form-item>
                                                    </div>
                                                </template>
                                            </el-tag>
                                        </template>
                                    </draggable>

                                </div>
                            </el-col>
                            <el-col :span="1" style="display: flex;align-items: center;">
                                <el-popconfirm title="确定要删除吗?" @confirm="handleRemove(index)">
                                    <template #reference>
                                        <el-icon style="cursor: pointer;font-size: 22px;">
                                            <delete />
                                        </el-icon>
                                    </template>
                                </el-popconfirm>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </template>
        </draggable>
        <el-button type="primary" icon="Plus" :size="size" title="添加" @click="addField" style="margin-top: 20px;">{{
            buttonText }}</el-button>

    </div>
</template>

<script setup>
import draggable from "vuedraggable"
import { ref, onMounted, watch, computed, nextTick } from 'vue'
import { ElMessage } from 'element-plus'

const props = defineProps({
    modelValue: { type: [Array], default: [] },
    size: { type: String, default: "small" },
    buttonText: { type: String, default: "添加" },
    placeholder1: { type: String, default: "请输入" },
    dragSort: { type: Boolean, default: true },
})

const emits = defineEmits(['update:modelValue'])

const dataList = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emits('update:modelValue', value)
    }
})

let rules = ref({
    label: [
        { required: true, message: '请输入标签名', trigger: 'blur' }
    ],
    value: [
        { required: true, message: '请输入标签值', trigger: 'blur' }
    ],
})

function handleRemove(index) {
    let newdatalist = dataList.value
    newdatalist.splice(index, 1)
    dataList.value = newdatalist
    delete treeRefObj["rulesForm" + index]
}
function addField() {
    let newdatalist = dataList.value
    newdatalist.push({ label: null, value: [{ value: null }] })
    dataList.value = newdatalist
}

function handleValueAdd(index) {
    // let newValue = "新标签值" + generateRandomNumbers()
    // if (isInArray(dataList.value[index].value, newValue)) {
    //     ElMessage.warning('存在相同的值')
    //     return
    // }
    dataList.value[index].value.push({ value: null })
}

function handelDelGroupValue(index1, index2) {

    dataList.value[index1].value.splice(index2, 1)
}

function generateRandomNumbers() {
    let randomnums = ""
    for (let i = 0; i < 3; i++) {
        randomnums = randomnums + (Math.floor(Math.random() * 10)).toString()
    }
    return randomnums
}

function isInArray(arr, a) {
    return arr.find(item => item.value === a) !== undefined;
}

function isInArrayNotSelf(arr, a, nindex) {
    return arr.find((item, index) => nindex != index && item.value === a) !== undefined;
}

let treeRefObj = {}
function setTreeRef(el, key) {
    if (el) {
        treeRefObj[key] = el
    }
}

function handleInputConfirm(val, index, nindex) {
    if (isInArrayNotSelf(dataList.value[index].value, val, nindex)) {
        ElMessage.warning('存在相同的值')
        return
    }
}

async function submitValidate() {
    try {
        let result = false
        for (var i in treeRefObj) {
            await treeRefObj[i].validate(obj => {
                if (obj) {
                    let tempdata = i.split("rulesForm")
                    if (dataList.value[parseInt(tempdata[1])].value.length < 1) {
                        result = false
                    } else {
                        result = true
                    }
                } else {
                    result = false
                }
            })
            if (!result) {
                break
            }
        }
        return result
    } catch (error) {
        return false
    }

}

defineExpose({
    submitValidate
})
</script>

<style scoped>
.drageltag .el-tag+.el-tag {
    margin: unset;
}

.drageltag .el-tag {
    padding-left: 3px;
}

.el-form .el-form-item {
    margin-bottom: unset;
}

.el-form .el-form-item:deep(.el-form-item__label:before) {
    content: "" !important;
}
</style>