<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-width="auto">
                <el-form-item label="时间：">
                    <el-date-picker style="width:350px" v-model="timers" type="datetimerange" @change="timeChange"
                        range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="">
                    <el-button @click="search" type="primary" icon="Search"
                        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button>
                </el-form-item>
                <el-form-item label="">
                    <el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div ref="orderStatic">
            <ul class="order-static">
                <li style="font-weight: bold;">数据统计</li>
                <li>订单总额：{{ orderstatics.orderamount }}</li>
                <li>订单数量：{{ orderstatics.ordernums }}</li>
                <li>退款金额：{{ orderstatics.refundamount }}</li>
                <li>退款数量：{{ orderstatics.refundnums }}</li>
                <li>分账金额：{{ orderstatics.fenzhangamount }}</li>
                <li>已提现金额：{{ orderstatics.totalcashout }}</li>
            </ul>
        </div>
        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
                <el-table-column min-width="110" prop="order_id" label="订单编号"></el-table-column>
                <el-table-column min-width="130" prop="freight" label="运费"></el-table-column>
                <el-table-column min-width="130" prop="total_amount" label="订单金额"></el-table-column>
                <!-- <el-table-column min-width="110" prop="username" label="所属商家"></el-table-column> -->
                <el-table-column min-width="120" prop="agent_fee" label="提成"></el-table-column>
                <el-table-column min-width="180" prop="create_datetime" label="创建时间"></el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { fmallAgentorderstatic, fmallAgentorderstaticStatics } from '@/api/api'
export default {
    components: {
        Pagination,
    },
    name: 'agentFinanceOrderStatic',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
            identityList: [
                { id: 0, name: '普通用户' },
                { id: 1, name: '会员' },
            ],
            timers: [],
            tableData: [],
            orderstatics: {
                fenzhangamount: 0,
                totalcashout: 0,
                refundnums: 0,
                refundamount: 0,
                ordernums: 0,
                orderamount: 0,
            }
        }
    },

    methods: {
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        handleEdit(row, flag) {
            if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.timers = []
                this.getData()
            }
        },
        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        getStatics() {
            fmallAgentorderstaticStatics(this.formInline).then(res => {
                if (res.code === 2000) {
                    this.orderstatics = res.data
                }
            })
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            fmallAgentorderstatic(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            let orderstaticHeight = 0
            if (this.$refs.orderStatic && this.$refs.orderStatic !== undefined) {
                orderstaticHeight = this.$refs.orderStatic.offsetHeight
            }
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 + orderstaticHeight : tabSelectHeight + orderstaticHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        }

    },
    created() {
        this.getData()
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
<style scoped>
.lystaticCard:deep(.el-card) .el-card__header {
    padding: 10px;
}

.order-static {
    display: flex;
    font-size: 14px;
    background: var(--l-order-statics);
    padding: 10px;

    li {
        margin-right: 30px;

    }
}
</style>
