<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="50%" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="城市：" prop="adcode">
                    <el-cascader style="width: 100%" :show-all-levels="false" :options="areaList" v-model="formData.adcode"
                        @change="handleChange"
                        :props="{ label: 'name', value: 'id', children: 'childlist', emitPath: false }" clearable
                        filterable></el-cascader>
                </el-form-item>
                <el-form-item label="花店信息：" prop="desc" class="is-required">
                    <div style="width: 100%;">
                        <div
                            style="display: flex;align-items: center;width: 100%;margin-bottom: 10px;border-bottom: 1px solid var(--el-border-color-lighter);">
                            <div style="width: 100%;">花店名</div>
                            <div style="width: 360px;">群二维码</div>
                        </div>
                        <Vuedraggable v-model="formData.desc" @dragend="videoScriptDragEnd" animation="200" item-key="id"
                            group="group_video_script" handle=".drag-option">
                            <template #item="{ element: item, index }">
                                <div
                                    style="display:flex;justify-content: space-between;align-items: center;margin-bottom:10px;">
                                    <el-icon class="drag-option" style="cursor: move;" size="20">
                                        <Rank />
                                    </el-icon>
                                    <el-input v-model="item.name" maxlength="100" clearable placeholder="请输入"
                                        style="width:100%;margin-right:20px;margin-left:5px;"></el-input>
                                    <div style="width: 300px;">
                                        <LyUploadAvatar v-model="item.qrcode" width="60px" height="60px"></LyUploadAvatar>
                                    </div>
                                    <span class="table-operate-btn" style="width:80px;text-align:right;"
                                        @click="handelDelScriptInfo(index)">删除</span>
                                </div>
                            </template>
                        </Vuedraggable>
                        <el-button type="primary" size="small" @click="handelAddScriptInfo">添加</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallCityshopimdAdd, fmallCityshopimEdit, getAllAreaslist } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
import { deepClone } from "@/utils/util"
import Vuedraggable from "vuedraggable";
export default {
    components: { LyDialog, LyUploadAvatar, Vuedraggable },
    emits: ['refreshData'],
    name: "addModuleXHShopIM",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                adcode: '',
                desc: [],
            },
            rules: {
                adcode: [
                    { required: true, message: '请选择城市', trigger: 'blur' }
                ],
            },
            areaList: []
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.dialogVisible = false
            this.loadingSave = false
            this.formData = {
                adcode: '',
                desc: [],
            }
        },
        handleChange(e) {
            // console.log(e)
        },
        handelAddScriptInfo() {
            if (!(!!this.formData.desc)) {
                this.formData.desc = []
            }
            this.formData.desc.push({ name: "", qrcode: "" })
        },
        handelDelScriptInfo(index) {
            this.formData.desc.splice(index, 1)
        },
        videoScriptDragEnd(e) {

        },
        addModuleFn(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData = deepClone(item)
            }
            this.getAllAreaslistData()
        },
        getAllAreaslistData() {
            getAllAreaslist({ subdistrict: 1 }).then(res => {
                if (res.code === 2000) {
                    this.areaList = res.data.data
                }
            })
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (!(!!param.desc) || param.desc.length < 1) {
                        this.$message.warning("请添加花店信息")
                        return
                    }
                    param.desc = JSON.stringify(param.desc)
                    if (this.formData.id) {
                        fmallCityshopimEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        fmallCityshopimdAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

