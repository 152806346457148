<!--
 * @Descripttion: 运费说明设置动态输入框组
 * @version: 1.0
 * @program: django-vue-lyadmin
 * @Author: lybbn
 * @Date: 2023.11.14
-->
<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="12">标题</el-col>
            <el-col :span="12">内容</el-col>
        </el-row>
        <draggable :list="dataList" item-key="id" :animation="300"
            v-bind="{ group: 'optionsGroup', ghostClass: 'ghost', handle: '.drag-option' }"
            v-if="!!dataList && dataList.length > 0">
            <template #item="{ element: item, index: index }">
                <div style="display: flex;align-items: center;margin-bottom: 5px;">
                    <div class="drag-option" style="cursor: move;margin-right: 10px;" v-if="dragSort">
                        <el-icon size="20">
                            <Rank />
                        </el-icon>
                    </div>
                    <el-input v-model="item.title" style="width: 100%" :placeholder="placeholder1"></el-input>
                    <el-input v-model="item.content" style="width: 100%;margin-left: 20px" :placeholder="placeholder2"
                        type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
                    <el-icon @click="handleRemove(index)" style="cursor: pointer;font-size: 22px;">
                        <delete />
                    </el-icon>
                </div>
            </template>

        </draggable>
        <el-button type="primary" icon="Plus" :size="size" title="添加" @click="addField">{{ buttonText }}</el-button>
    </div>
</template>

<script setup>
import draggable from "vuedraggable"
import { ref, onMounted, watch, computed, nextTick } from 'vue'

const props = defineProps({
    modelValue: { type: [Array], default: [] },
    size: { type: String, default: "small" },
    buttonText: { type: String, default: "添加" },
    placeholder1: { type: String, default: "请输入" },
    placeholder2: { type: String, default: "请输入" },
    dragSort: { type: Boolean, default: true },
})

const emits = defineEmits(['update:modelValue'])

const dataList = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emits('update:modelValue', value)
    }
})

function handleRemove(index) {
    let newdatalist = dataList.value
    newdatalist.splice(index, 1)
    dataList.value = newdatalist
}
function addField() {
    let newdatalist = dataList.value
    newdatalist.push({ title: null, content: null })
    dataList.value = newdatalist
}

defineExpose({

})
</script>

<style scoped></style>