<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="50%" top="10px" :before-close="handleClose"
            :draggable="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <div class="ly-title">
                    发布人信息
                </div>
                <el-form-item label="发布人：" prop="pub_user">
                    <el-input type="text" v-model.trim="formData.pub_user"></el-input>
                </el-form-item>
                <el-form-item label="发布人头像：" prop="pub_avatar">
                    <LyUploadAvatar v-model="formData.pub_avatar" width="100px" height="100px"></LyUploadAvatar>
                </el-form-item>
                <div class="ly-title">
                    基础信息
                </div>
                <el-form-item label="标题：" prop="title">
                    <el-input type="text" v-model.trim="formData.title"></el-input>
                </el-form-item>
                <el-form-item label="封面图：" prop="cover">
                    <LyUploadAvatar v-model="formData.cover" width="100px" height="100px"></LyUploadAvatar>
                </el-form-item>
                <el-form-item label="图文详情：" prop="desc">
                    <TEditor v-model="formData.desc" style="height: 200px;"></TEditor>
                </el-form-item>
                <el-form-item label="展示分类：" prop="types">
                    <el-select size="default" multiple v-model="formData.types" placeholder="请选择" clearable
                        style="width: 100%">
                        <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallNoticeAdd, fmallNoticeEdit, fmallGoodstype } from "@/api/api";
import TEditor from '@/components/TEditor'
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
import { deepClone } from "@/utils/util"
export default {
    components: { LyDialog, TEditor, LyUploadAvatar },
    emits: ['refreshData'],
    name: "addModuleNotice",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                title: '',
                cover: '',
                pub_user: '',
                pub_avatar: '',
                desc: '',
                types: [],
                status: true
            },
            typeList: [],
            rules: {
                title: [
                    { required: true, message: '请填写公告标题', trigger: 'blur' }
                ],
                cover: [
                    { required: true, message: '请上传封面', trigger: 'blur' }
                ],
                pub_user: [
                    { required: true, message: '请填写发布人', trigger: 'blur' }
                ],
                pub_avatar: [
                    { required: true, message: '请上传发布人头像', trigger: 'blur' }
                ],
                desc: [
                    { required: true, message: '请填写图文详情', trigger: 'blur' }
                ]
            }
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.dialogVisible = false
            this.loadingSave = false
            this.formData = {
                title: '',
                cover: '',
                pub_user: '',
                pub_avatar: '',
                desc: '',
                types: [],
                status: true
            }
        },
        getGoodsTypeList() {
            fmallGoodstype({ page: 1, limit: 999 }).then(res => {
                if (res.code === 2000) {
                    this.typeList = res.data.data
                }
            })
        },
        addModuleFn(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            this.getGoodsTypeList()
            if (item) {
                this.formData = deepClone(item)
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        fmallNoticeEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        fmallNoticeAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.ly-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    font-size: 17px;
    padding-bottom: 15px;
    color: #3c4a54;
    font-weight: bold;
}

.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

