<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="60%" top="10px" @closed="handleClose"
            :fullscreen="false" :draggable="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <div class="ly-title">
                    操作记录
                </div>
                <el-table border :data="formData.log_data">
                    <el-table-column width="110" prop="type_name" label="类型"></el-table-column>
                    <el-table-column width="170" prop="create_datetime" label="时间"></el-table-column>
                    <el-table-column width="120" prop="userinfo.name" label="操作人"></el-table-column>
                    <el-table-column min-width="160" prop="info" label="操作信息"></el-table-column>
                </el-table>
                <div class="ly-title" style="margin-top: 10px;">
                    缺货退款
                </div>
                <el-row :gutter="20"
                    v-if="!!formData.user_lackgoods_refund_info && JSON.stringify(formData.user_lackgoods_refund_info) != '{}'">
                    <el-col :span="24">
                        <el-form-item label="缺货原因：">
                            <div style="display: flex;flex-direction: column;">
                                <div v-for="item in formData.user_lackgoods_refund_info.reason">
                                    <span>{{ item.goods_name }}</span><span>-</span><span>{{ item.count
                                    }}</span><span>-</span><span>{{ item.reason }}</span>
                                </div>
                            </div>

                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款时间：">
                            {{ formData.user_lackgoods_refund_info.pay_time }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款金额：">
                            {{ formData.user_lackgoods_refund_info.amount }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                    运费退款
                </div>
                <el-row :gutter="20"
                    v-if="!!formData.user_freight_refund_info && JSON.stringify(formData.user_freight_refund_info) != '{}'">
                    <el-col :span="8">
                        <el-form-item label="退款类型：">
                            {{ formData.user_freight_refund_info.type }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款金额：">
                            {{ formData.user_freight_refund_info.amount }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款时间：">
                            {{ formData.user_freight_refund_info.pay_time }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20"
                    v-if="!!formData.user_freight_pay_info && JSON.stringify(formData.user_freight_pay_info) != '{}'">
                    <el-col :span="8">
                        <el-form-item label="退款类型：">
                            {{ formData.user_freight_pay_info.type }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="补交金额：">
                            {{ formData.user_freight_pay_info.amount }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="支付时间：">
                            {{ formData.user_freight_pay_info.pay_time }}
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallOrderOperateLog } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
export default {
    name: "addModuleMallOrderOPRecord",
    components: { LyDialog, LyUploadAvatar },
    emits: ['refreshData', 'closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            id: "",
            formData: {
                payinfo: {},
                user_refund_info: {},
                user_freight_refund_info: {},
                user_lackgoods_refund_info: {},
                user_freight_pay_info: {},
                commentinfo: [],
                log_data: [],
            },
            rules: {
                // username: [
                //     { required: true, message: '请输入用户名', trigger: 'blur' }
                // ],
            },
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.id = item.id
                this.getOderOperateLog(this.id)
            }
        },
        getOderOperateLog(id) {
            fmallOrderOperateLog({ id: id }).then(res => {
                if (res.code === 2000) {
                    this.formData = res.data
                }
            })
        },
    }
}
</script>
<style scoped>
.ly-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    font-size: 17px;
    padding-bottom: 15px;
    color: #3c4a54;
    font-weight: bold;
}
</style>

