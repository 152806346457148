<template>
    <div :class="{ 'ly-is-full': isFull }" class="financeOrderStaticcss">
        <el-tabs v-model="formInline.type" @tab-click="handleTabClick" ref="eltabselect">
            <el-tab-pane label="代理统计" name="1"></el-tab-pane>
            <el-tab-pane label="商家统计" name="2"></el-tab-pane>
        </el-tabs>
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-width="auto">
                <el-form-item label="订单编号：">
                    <el-input style="width:160px" v-model.trim="formInline.order_id" maxlength="60" clearable
                        placeholder="订单编号" @change="search"></el-input>
                </el-form-item>
                <el-form-item label="所属商家：" v-if="formInline.type == '2'">
                    <el-input style="width:160px" v-model.trim="formInline.nickname" maxlength="60" clearable
                        placeholder="所属商家" @change="search"></el-input>
                </el-form-item>
                <el-form-item label="所属代理：" v-if="formInline.type == '1'">
                    <el-input style="width:160px" v-model.trim="formInline.belong_agent_name" maxlength="60" clearable
                        placeholder="所属代理" @change="search"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-button @click="search" type="primary" icon="Search"
                        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button>
                </el-form-item>
                <el-form-item label="">
                    <el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div ref="orderStatic">
            <ul class="order-static" v-if="formInline.type == '1'">
                <li style="font-weight: bold;">代理统计</li>
                <li>订单总额：{{ orderstatics.orderamount }}</li>
                <li>订单数量：{{ orderstatics.ordernums }}</li>
                <li>退款金额：{{ orderstatics.refundamount }}</li>
                <li>退款数量：{{ orderstatics.refundnums }}</li>
                <li>分账金额：{{ orderstatics.fenzhangamount }}</li>
                <li>已提现金额：{{ orderstatics.totalcashout }}</li>
            </ul>
            <ul class="order-static" v-if="formInline.type == '2'">
                <li style="font-weight: bold;">商家统计</li>
                <li>订单总额：{{ orderstatics.orderamount }}</li>
                <li>订单数量：{{ orderstatics.ordernums }}</li>
                <li>退款金额：{{ orderstatics.refundamount }}</li>
                <li>退款数量：{{ orderstatics.refundnums }}</li>
                <li>总收入：{{ orderstatics.fenzhangamount }}</li>
                <li>已提现金额：{{ orderstatics.totalcashout }}</li>
            </ul>
        </div>
        <div class="table" v-if="formInline.type == '1'">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
                <el-table-column min-width="110" prop="order_id" label="订单编号"></el-table-column>
                <el-table-column min-width="170" prop="create_datetime" label="创建时间"></el-table-column>
                <el-table-column min-width="110" prop="total_amount" label="订单金额"></el-table-column>
                <el-table-column min-width="110" prop="freight" label="运费金额"></el-table-column>
                <el-table-column min-width="110" prop="freight_amount" label="运费补交">
                    <template #default="scope">
                        <span v-if="scope.row.freight_type == 1">{{ "+" + scope.row.freight_amount }}</span>
                        <span v-else-if="scope.row.freight_type == 2">{{ "-" + scope.row.freight_amount }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column min-width="110" prop="agent_name" label="所属代理"></el-table-column>
                <el-table-column min-width="110" prop="agent_fee" label="代理收入"></el-table-column>
                <el-table-column min-width="110" prop="settled_refund_amount" label="退款金额"></el-table-column>
                <el-table-column min-width="110" prop="settled_surplus_amount" label="剩余金额"></el-table-column>
            </el-table>
        </div>
        <div class="table" v-if="formInline.type == '2'">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
                <el-table-column min-width="110" prop="order_id" label="订单编号"></el-table-column>
                <el-table-column min-width="170" prop="create_datetime" label="创建时间"></el-table-column>
                <el-table-column min-width="110" prop="total_amount_shop" label="订单金额"></el-table-column>
                <el-table-column min-width="110" prop="shop_name" label="所属商家"></el-table-column>
                <el-table-column min-width="110" prop="settled_income_amount" label="商家收入"></el-table-column>
                <el-table-column min-width="110" prop="settled_refund_amount" label="退款金额"></el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { fmallFinanceorder, fmallFinanceorderStatics } from '@/api/api'
export default {
    components: {
        Pagination,
    },
    name: 'financeOrderStatic',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                type: '1',
                page: 1,
                limit: 10,
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
            timers: [],
            tableData: [],
            orderstatics: {
                fenzhangamount: 0,
                totalcashout: 0,
                refundnums: 0,
                refundamount: 0,
                ordernums: 0,
                orderamount: 0,
            }
        }
    },

    methods: {
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        handleTabClick(tab, e) {
            this.formInline.type = tab.props.name
            this.search()
        },
        handleEdit(row, flag) {
            if (flag == "reset") {
                this.formInline = {
                    type: '1',
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.getData()
            }
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
            this.getStatics()
        },
        getStatics() {
            fmallFinanceorderStatics(this.formInline).then(res => {
                if (res.code === 2000) {
                    this.orderstatics = res.data
                }
            })
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            fmallFinanceorder(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },
        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            let orderstaticHeight = 0
            if (this.$refs.orderStatic && this.$refs.orderStatic !== undefined) {
                orderstaticHeight = this.$refs.orderStatic.offsetHeight
            }
            let eltabselectHeight = 40
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 + orderstaticHeight + eltabselectHeight : tabSelectHeight + orderstaticHeight + eltabselectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        }

    },
    created() {
        this.search()
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
<style scoped>
.lystaticCard:deep(.el-card) .el-card__header {
    padding: 10px;
}

.order-static {
    display: flex;
    font-size: 14px;
    background: var(--l-order-statics);
    padding: 10px;

    li {
        margin-right: 30px;

    }
}

.financeOrderStaticcss .el-tabs {
    background: var(--el-bg-color);
    border: 1px solid var(--el-border-color-light);
    border-bottom: unset;
}

.financeOrderStaticcss:deep(.el-tabs) .el-tabs__item {
    padding-left: 10px;
}

.financeOrderStaticcss:deep(.el-tabs) .el-tabs__header {
    margin: unset;
}
</style>
