// 打印类属性、方法定义（默认去除页眉和当前网址，保留页数）
/* eslint-disable */
const Print = function (dom, options) {
  if (!(this instanceof Print)) return new Print(dom, options);

  this.options = this.extend(
    {
      noPrint: ".no-print",
    },
    options
  );
  this.options.hideHeaderFooter =
    this.options.hideHeaderFooter === null ||
    this.options.hideHeaderFooter === undefined
      ? false
      : this.options.hideHeaderFooter;

  if (typeof dom === "string") {
    try {
      this.dom = document.querySelector(dom);
    } catch {
      var createDom = document.createElement("div");
      createDom.innerHTML = dom;
      this.dom = createDom;
    }
  } else {
    this.isDOM(dom);
    this.dom = this.isDOM(dom) ? dom : dom.$el;
  }

  this.init();
};
Print.prototype = {
  init: function () {
    var content = this.getStyle() + this.getHtml();
    this.writeIframe(content);
  },
  extend: function (obj, obj2) {
    for (var k in obj2) {
      obj[k] = obj2[k];
    }
    return obj;
  },
  getStyle: function () {
    var str = "",
      styles = document.querySelectorAll("style,link");
    for (var i = 0; i < styles.length; i++) {
      str += styles[i].outerHTML;
    }
    str +=
      "<style>" +
      (this.options.noPrint ? this.options.noPrint : ".no-print") +
      "{display:none;}</style>";
    str += "<style>html,body{background-color:#fff;}</style>";
    // str += "<style>body{zoom: 90%;}</style>";//设置默认打印缩放比
    // str += "<style>html,body,div{height: auto!important;}</style>"; //打印多页
    // str += "<style>@page {size: auto;margin: 0 6mm 10mm 6mm;}</style>";//去除页眉,其中size是纸张尺寸如：A4    size:A4;  portrait 或 landscape 为纸张方向， 如A4横向 size:A4 landscape;
    // str += "<style>@page {size: auto;margin: 6mm 10mm 6mm 10mm;}</style>";//去除页眉和页脚
    if (this.options.hideHeaderFooter) {
      str += "<style>@page {size: auto;margin: 8mm 15mm 8mm 15mm;}</style>"; //只保留页码（去除页眉和隐藏页脚左侧的链接，打印预览需勾选【页眉和页脚】），其中margin-bottom的值多少决定是否能隐藏链接，存在的问题：添加 “ page-break-inside:avoid; ” 分页属性时，会使其链接显示出来，如果不显示左侧链接就不要使用此属性
    }
    return str;
  },

  getHtml: function () {
    var inputs = document.querySelectorAll("input");
    var textareas = document.querySelectorAll("textarea");
    var selects = document.querySelectorAll("select");

    for (var k = 0; k < inputs.length; k++) {
      if (inputs[k].type == "checkbox" || inputs[k].type == "radio") {
        if (inputs[k].checked == true) {
          inputs[k].setAttribute("checked", "checked");
        } else {
          inputs[k].removeAttribute("checked");
        }
      } else if (inputs[k].type == "text") {
        inputs[k].setAttribute("value", inputs[k].value);
      } else {
        inputs[k].setAttribute("value", inputs[k].value);
      }
    }

    for (var k2 = 0; k2 < textareas.length; k2++) {
      if (textareas[k2].type == "textarea") {
        textareas[k2].innerHTML = textareas[k2].value;
      }
    }

    for (var k3 = 0; k3 < selects.length; k3++) {
      if (selects[k3].type == "select-one") {
        var child = selects[k3].children;
        for (var i in child) {
          if (child[i].tagName == "OPTION") {
            if (child[i].selected == true) {
              child[i].setAttribute("selected", "selected");
            } else {
              child[i].removeAttribute("selected");
            }
          }
        }
      }
    }

    return this.dom.outerHTML;
  },

  writeIframe: function (content) {
    var w,
      doc,
      iframe = document.createElement("iframe"),
      f = document.body.appendChild(iframe);
    iframe.id = "myIframe";
    //iframe.style = "position:absolute;width:0;height:0;top:-10px;left:-10px;";
    iframe.setAttribute(
      "style",
      "position:absolute;width:0;height:0;top:-10px;left:-10px;"
    );
    w = f.contentWindow || f.contentDocument;
    doc = f.contentDocument || f.contentWindow.document;
    doc.open();
    doc.write(content);
    doc.close();
    var _this = this;
    iframe.onload = function () {
      _this.toPrint(w);
      setTimeout(function () {
        document.body.removeChild(iframe);
      }, 100);
    };
  },

  toPrint: function (frameWindow) {
    try {
      setTimeout(function () {
        frameWindow.focus();
        try {
          if (!frameWindow.document.execCommand("print", false, null)) {
            frameWindow.print();
          }
        } catch (e) {
          frameWindow.print();
        }
        frameWindow.close();
      }, 10);
    } catch (err) {
      console.log("err", err);
    }
  },
  isDOM:
    typeof HTMLElement === "object"
      ? function (obj) {
          return obj instanceof HTMLElement;
        }
      : function (obj) {
          return (
            obj &&
            typeof obj === "object" &&
            obj.nodeType === 1 &&
            typeof obj.nodeName === "string"
          );
        },
};

export default Print;
