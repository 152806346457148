<!--
 * @Descripttion: 数字范围选择
 * @version: 1.0
 * @program: django-vue-lyadmin
 * @Author: lybbn
 * @Date: 2023.11.15
-->
<template>
    <div>
        <div style="display: flex;">
            <el-select v-model="dataList[0]" :placeholder="placeholder" :size="size" style="width: 100%;"
                @change="startChange" clearable>
                <el-option v-for="item in dataRangeList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
            <div style="margin-left: 10px;margin-right: 10px;">{{ rangeSeparator }}</div>
            <el-select v-model="dataList[1]" :placeholder="placeholder" :size="size" style="width: 100%;"
                @change="endChange" clearable>
                <el-option v-for="item in dataRangeList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'

const props = defineProps({
    modelValue: { type: [Array], default: [null, null] },
    size: { type: String, default: "default" },
    placeholder: { type: String, default: "请选择" },
    rangeSeparator: { type: String, default: "-" },
})

const emits = defineEmits(['update:modelValue'])

const dataRangeList = [{ id: "1", name: "1" }, { id: "2", name: "2" }, { id: "3", name: "3" }, { id: "4", name: "4" }, { id: "5", name: "5" }, { id: "6", name: "6" }, { id: "7", name: "7" }, { id: "8", name: "8" }, { id: "9", name: "9" }, { id: "10", name: "10" }, { id: "11", name: "11" }, { id: "12", name: "12" }, { id: "13", name: "13" }, { id: "14", name: "14" }, { id: "15", name: "15" }, { id: "16", name: "16" }, { id: "17", name: "17" }, { id: "18", name: "18" }, { id: "19", name: "19" }, { id: "20", name: "20" }, { id: "21", name: "21" }, { id: "22", name: "22" }, { id: "23", name: "23" }, { id: "24", name: "24" }, { id: "25", name: "25" }, { id: "26", name: "26" }, { id: "27", name: "27" }, { id: "28", name: "28" }]

const dataList = computed({
    get() {
        return (!!props.modelValue && props.modelValue.length == 2) ? props.modelValue : [null, null]
    },
    set(value) {
        emits('update:modelValue', value)
    }
})

function startChange(val) {
    emits('update:modelValue', dataList.value)

}
function endChange(val) {
    emits('update:modelValue', dataList.value)
}


defineExpose({

})
</script>

<style scoped></style>