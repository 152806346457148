<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="缺货入位：" prop="ruwei_reason">
                    <el-input v-model="formData.ruwei_reason" :autosize="{ minRows: 2, maxRows: 4 }"
                        type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallOrdergoodsRuwei } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
export default {
    name: "addModuleInsertion",
    components: { LyDialog },
    emits: ['refreshData', 'closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            formData: {
                ruwei_reason: '',
                id: '',
                ruwei_count: 0,
            },
            rules: {
                ruwei_reason: [
                    { required: true, message: '请输入缺货入位原因', trigger: 'blur' }
                ],
            },
            rolelist: [],
            options: [],
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData.ruwei_count = item.ruwei_count
                this.formData.id = item.id
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    fmallOrdergoodsRuwei(param).then(res => {
                        this.loadingSave = false
                        if (res.code == 2000) {
                            this.$message.success(res.msg)
                            this.handleClose()
                            this.$emit('refreshData')
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })

                }
            })
        },
    }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
}

.avatar {
    width: 128px;
    height: 128px;
    display: block;
}
</style>

