<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="568px" @closed="handleClose" :fullscreen="true"
            :draggable="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <div class="ly-title">
                    基础信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="选择商品：" prop="name">
                            <el-select v-model="formData.name" placeholder="请选择" style="width: 100%;" filterable clearable
                                @change="handleGoodsSelect">
                                <el-option v-for=" item in goodsList " :key="item.id" :label="item.goods_name"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="鲜花类型：">
                            <span v-if="selectGoodsinfo.flower_type == 1">主花</span>
                            <span v-else-if="selectGoodsinfo.flower_type == 2">配花</span>
                            <span v-else></span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="商品等级：" prop="level">
                            <el-select v-model="formData.level" placeholder="请选择" style="width: 100%;" filterable clearable>
                                <el-option v-for=" item  in  levelList " :key="item.id" :label="item.name"
                                    :value="item.id" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="品种规格：">
                            <el-input v-model="formData.breed_spec" :placeholder="disabledSpec ? '展示默认' : '请输入（单位：/扎）'"
                                :disabled="disabledSpec"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="规格备注：">
                            <el-input v-model="formData.spec_desc" :placeholder="disabledSpec ? '展示默认' : '请输入（5字内）'"
                                :disabled="disabledSpec" :maxlength="5"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="规格估重：">
                            <el-input-number v-model="formData.spec_weight"
                                :placeholder="disabledSpec ? '展示默认' : '请输入（单位：kg/扎）'" :disabled="disabledSpec"
                                style="width: 100%;" :controls="false"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="封面图：" prop="cover">
                            <LyUploadAvatar v-model="formData.cover" width="100px" height="100px"></LyUploadAvatar>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="轮播图：" prop="imgs">
                            <Mutiplepictures v-model="formData.imgs" :draggable="true" width="145px" height="145px">
                            </Mutiplepictures>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="商品库存：" prop="stock">
                            <el-input-number v-model="formData.stock" style="width: 100%;"
                                :controls="false"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="商品价格：" prop="price">
                            <el-input-number v-model="formData.price" style="width: 100%;"
                                :controls="false"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="发货信息：" prop="fh_info">
                            <TEditor v-model="formData.fh_info" style="height:200px"></TEditor>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="商品说明：" prop="goods_desc">
                            <el-input v-model="formData.goods_desc" style="width: 100%;"
                                :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="商品排序：" prop="sort">
                            <el-input-number v-model="formData.sort" style="width: 100%;" :controls="false"
                                placeholder="数字越大越靠前"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                    选择标签
                </div>
                <div v-if="selectGoodsinfo.config.length > 0">
                    <el-form-item :label="item.label + '：'" v-for="(item, index) in selectGoodsinfo.config">
                        <el-select v-model="formData.label[index].value" placeholder="请选择" style="width: 100%;" filterable>
                            <el-option v-for=" itemn in item.value " :label="itemn.value" :value="itemn.value" />
                        </el-select>
                    </el-form-item>
                </div>
                <div class="ly-title" v-if="formData.type == 1">
                    加价信息
                </div>
                <el-form-item label="商品加价：" prop="j_price" v-if="formData.type == 1">
                    <el-input-number v-model="formData.j_price" style="width: 100%;" :controls="false"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallGoodsAdd, fmallGoodsEdit, fmallGoodsBreed, fmallGoodsGetlevelList } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
import LyUploadAvatar from "@/components/upload/avatar";
import Mutiplepictures from "@/components/upload/mutiplepictures";
import TEditor from '@/components/TEditor'

export default {
    components: { LyDialog, LyUploadAvatar, Mutiplepictures, TEditor },
    emits: ['refreshData', 'closed'],
    name: "addModuleGoods",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            selectGoodsinfo: { breed_spec: '', spec_desc: '', spec_weight: '', config: [], flower_type: null, },
            labelConfigSelect: {},
            disabledSpec: true,
            formData: {
                stock: null,
                price: null,
                sort: 1,
                fh_info: "",
                imgs: [],
                cover: "",
                label: [],
                type: 0,
                breed_spec: "",
                spec_desc: "",
                spec_weight: 0,
            },
            rules: {
                name: [
                    { required: true, message: '请选择商品', trigger: 'blur' }
                ],
                imgs: [
                    { required: true, message: '请上传轮播图', trigger: 'blur' }
                ],
                cover: [
                    { required: true, message: '请上传封面图', trigger: 'blur' }
                ],
                fh_info: [
                    { required: true, message: '请输入发货信息', trigger: 'blur' }
                ],
                stock: [
                    { required: true, message: '请输入库存', trigger: 'blur' }
                ],
                price: [
                    { required: true, message: '请输入价格', trigger: 'blur' }
                ],
                level: [
                    { required: true, message: '请选择等级', trigger: 'blur' }
                ],

            },
            typeList: [],
            levelList: [],
            goodsList: []
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData = deepClone(item)
                this.selectGoodsinfo = item.nameinfo
                this.handleGoodsSelect(this.formData.name)
            }
            this.getSelectData()
        },
        getSelectData() {
            fmallGoodsBreed({ page: 1, limit: 999 }).then(res => {
                if (res.code === 2000) {
                    this.goodsList = res.data.data
                }
            })
        },
        getLevelByBreedID(id) {
            fmallGoodsGetlevelList({ id: id }).then(res => {
                if (res.code === 2000) {
                    this.levelList = res.data.data
                }
            })
        },
        handleGoodsSelect(e) {
            if (!!e) {
                this.getLevelByBreedID(e)
                this.goodsList.forEach(item => {
                    if (item.id == e) {
                        this.selectGoodsinfo = item
                    }
                });
                if (!!this.selectGoodsinfo.config) {
                    this.selectGoodsinfo.config.forEach(item => {
                        this.formData.label.push({ label: item.label, value: null })
                    })
                }

                if (this.selectGoodsinfo.breed_spec_type == 1) {
                    this.disabledSpec = true
                    this.formData.breed_spec = this.selectGoodsinfo.breed_spec
                    this.formData.spec_desc = this.selectGoodsinfo.spec_desc
                    this.formData.spec_weight = this.selectGoodsinfo.spec_weight
                } else {
                    this.disabledSpec = false
                    this.formData.breed_spec = ""
                    this.formData.spec_desc = ""
                    this.formData.spec_weight = 0
                }
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        fmallGoodsEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        fmallGoodsAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style scoped>
.ly-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    font-size: 17px;
    padding-bottom: 15px;
    color: #3c4a54;
    font-weight: bold;
}

.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

