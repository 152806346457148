<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-width="auto">
                <div style="width: 100%;">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="员工姓名：">
                                <el-input style="width:100%" v-model.trim="formInline.nickname" maxlength="60" clearable
                                    placeholder="员工姓名" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="日期：">
                                <el-date-picker style="width:100%" v-model="timers" type="datetimerange"
                                    @change="timeChange" range-separator="至" start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="">
                                <el-button @click="search" type="primary" icon="Search"
                                    v-show="hasPermission(this.$route.name, 'Search')">查询</el-button>
                                <el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>
        <div class="lystaticCard">
            <el-scrollbar :height="tableHeight">
                <el-card header="验货统计" shadow="hover">
                    <div>
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <span>验货数量：</span>/扎
                            </el-col>
                            <el-col :span="6">
                                <span>合格数量：</span>/扎
                            </el-col>
                            <el-col :span="6">
                                <span>合格率：</span>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
                <el-card header="入位统计" shadow="hover" style="margin-top: 10px;">
                    <div>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <span>入位商品数量：</span>/扎
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
                <el-card header="打包统计" shadow="hover" style="margin-top: 10px;">
                    <div>
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <span>大包：</span>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
                <el-card header="发货数量" shadow="hover" style="margin-top: 10px;margin-bottom: 20px;">
                    <div>
                        <el-row :gutter="20">
                            <el-col :span="6">
                                <span>发货数量：</span>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
            </el-scrollbar>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { UsersUsers, UsersUsersDelete, UsersUsersdisableEdit } from '@/api/api'
export default {
    components: {
        Pagination,
    },
    name: 'mallOrderStatic',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
            identityList: [
                { id: 0, name: '普通用户' },
                { id: 1, name: '会员' },
            ],
            timers: [],
            tableData: [],
        }
    },

    methods: {
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        handleEdit(row, flag) {
            if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.getData()
            }
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            UsersUsers(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight) + 50
        }

    },
    created() {
        this.getData()
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
<style scoped>
.lystaticCard:deep(.el-card) .el-card__header {
    padding: 10px;
}
</style>
