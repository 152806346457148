<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="680px" @closed="handleClose" :draggable="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-width="auto">
                <el-form-item label="鲜花类型：" prop="type">
                    <el-radio-group v-model="formData.type" :disabled="loadingTitle == '编辑'">
                        <el-radio :label="1">限时秒杀</el-radio>
                        <el-radio :label="2">团购</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="品种规格：" prop="spec" v-if="formData.type == 2">
                    <el-input v-model.trim="formData.spec" placeholder="请输入（单位：/扎）"></el-input>
                </el-form-item>
                <el-form-item label="选择商品：" prop="product">
                    <span style="margin-right:10px;">{{ !!formData.product ? "已选择" : '请选择' }}</span>
                    <LyTableSelect :apiObj="getGoodsList" buttonText="点击选择" table-width="50%" clearable ref="lymanualSelect"
                        @click="handleManualSelectClick" @confirm="handleManualConfirmClick" :params="{ type: 0 }">
                        <template #header="{ form, submit }">
                            <el-form :inline="true" :model="form">
                                <el-form-item>
                                    <el-select v-model="form.gtype" placeholder="选择分类" style="width: 130px;" filterable
                                        clearable>
                                        <el-option v-for="  item  in  goodsTypeList  " :key="item.id" :label="item.name"
                                            :value="item.id" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-input type="text" v-model="form.name" placeholder="请输入商品名称"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="submit">查询</el-button>
                                </el-form-item>
                            </el-form>
                        </template>
                        <!-- <el-table-column prop="id" label="ID" width="100" show-overflow-tooltip></el-table-column> -->
                        <el-table-column prop="nameinfo.goods_name" label="商品名称" min-width="100"
                            show-overflow-tooltip></el-table-column>
                        <el-table-column prop="cover" label="图片" width="100">
                            <template #default="scope">
                                <el-image :src=scope.row.cover :preview-src-list="[scope.row.cover]"
                                    :preview-teleported="true" style="width: 35px;height: 35px"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nameinfo.type_name" label="所属分类" width="150"></el-table-column>
                        <el-table-column prop="price" label="价格" width="100"></el-table-column>
                    </LyTableSelect>
                </el-form-item>
                <el-form-item label="活动价格：" prop="price">
                    <el-input-number v-model="formData.price" style="width: 100%;" :controls="false"></el-input-number>
                </el-form-item>
                <el-form-item label="商品库存：" prop="stock">
                    <el-input-number v-model="formData.stock" style="width: 100%;" :controls="false"></el-input-number>
                </el-form-item>
                <el-form-item label="活动时间：" class="is-required">
                    <div style="display: flex;width:100%;">
                        <el-date-picker v-model="formData.start_time" type="datetime" placeholder="开始日期"
                            format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" style="width: 100%;" />
                        <div style="width: 100px;text-align: center;">至</div>
                        <el-date-picker v-model="formData.end_time" type="datetime" placeholder="结束日期"
                            format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" style="width: 100%;" />
                    </div>
                </el-form-item>
                <el-form-item label="团购详情：" prop="desc" v-if="formData.type == 2" class="is-required">
                    <TEditor v-model="formData.desc" style="height:200px"></TEditor>
                </el-form-item>
                <el-form-item label="商品加价：" prop="j_price" v-if="!!formData.shop_id" class="is-required">
                    <el-input-number v-model="formData.j_price" style="width: 100%;" :controls="false"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallActiveAdd, fmallActiveEdit, fmallGoods, fmallGoodstype } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
import TEditor from '@/components/TEditor'
import LyTableSelect from "@/components/lyTableSelectV2";
export default {
    components: { LyDialog, TEditor, LyTableSelect },
    emits: ['refreshData', 'closed'],
    name: "addModuleActive",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                name: '',
                type: 1,
            },
            selectData: {},
            rules: {
                product: [
                    { required: true, message: '请选择商品', trigger: 'blur' }
                ],
                stock: [
                    { required: true, message: '请输入商品库存', trigger: 'blur' }
                ],
                price: [
                    { required: true, message: '请输入活动价', trigger: 'blur' }
                ],
            },
            goodsTypeList: [],
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        getGoodsList() {
            return fmallGoods
        },
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData = deepClone(item)
            }
            this.getGoodsTypeList()
        },
        handleManualSelectClick() {
            this.$refs.lymanualSelect.handleChooseClick()
        },
        handleManualConfirmClick(items) {
            this.formData.product = items.id
        },
        getGoodsTypeList() {
            fmallGoodstype({ page: 1, limit: 999 }).then(res => {
                if (res.code === 2000) {
                    this.goodsTypeList = res.data.data
                }
            })
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (param.type == 2) {
                        if (param.desc == null || param.desc == "" || param.desc == undefined) {
                            this.$message.warning("请填写团购详情")
                            return
                        }
                    }
                    if (this.formData.id) {
                        if (param.shop_id) {
                            if (param.j_price == null || param.j_price == "" || param.j_price == undefined) {
                                this.$message.warning("请填写商品加价")
                                return
                            }
                        }
                        fmallActiveEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        fmallActiveAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

