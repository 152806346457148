<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="订单重量：" prop="real_weight" class="is-required">
                    <el-input-number v-model="formData.real_weight" @change="handleChange" :controls="false"
                        style="width: 100%;" placeholder="请输入"></el-input-number>
                </el-form-item>
                <el-form-item label="类型：" prop="freight_type" v-if="formData.freight_type_name">
                    {{ formData.freight_type_name }}
                </el-form-item>
                <el-form-item label="操作金额：" prop="freight_amount" v-if="formData.freight_type_name" class="is-required">
                    <el-input-number v-model="formData.freight_amount" :controls="false" style="width: 100%;"
                        placeholder="请输入"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallOrderYunfeibk } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
import { ElInputNumber } from "element-plus";
export default {
    name: "addModuleYunFei",
    components: { LyDialog, ElInputNumber },
    emits: ['refreshData', 'closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            formData: {
                id: "",
                real_weight: 0,
                freight_type: 0,
                freight_amount: 0,
                weight: 0,
                freight_type_name: "",
                freight: 0,
            },
            rules: {
                // nickname: [
                //     {required: true, message: '请输入昵称',trigger: 'blur'}
                // ],
            },
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                let tempData = deepClone(item)
                this.formData.real_weight = tempData.weight
                this.formData.weight = tempData.weight
                this.formData.freight = parseFloat(tempData.freight)
                this.formData.id = tempData.id
                this.formData.freight_amount = parseFloat(tempData.freight)
            }
        },
        handleChange(val) {
            if (val > this.formData.weight) {
                this.formData.freight_type_name = "补运费"
                this.formData.freight_type = 1
            } else if (val < this.formData.weight) {
                this.formData.freight_type_name = "退运费"
                this.formData.freight_type = 2
            } else {
                this.formData.freight_type_name = ""
                this.formData.freight_type = 0
            }
            this.getYunfeiNewFreight()
        },
        getYunfeiNewFreight() {
            let params = { id: this.formData.id, orderfreight: 1, real_weight: this.formData.real_weight }
            fmallOrderYunfeibk(params).then(res => {
                if (res.code === 2000) {
                    this.formData.freight_amount = res.data
                }
            })
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (param.freight_type == 2 && param.freight_amount > param.freight) {
                        this.$message.warning("退运费的金额不能大于原运费")
                        return
                    }
                    fmallOrderYunfeibk(param).then(res => {
                        this.loadingSave = false
                        if (res.code == 2000) {
                            this.$message.success(res.msg)
                            this.dialogVisible = false;
                            this.handleClose()
                            this.$emit('refreshData')
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            })
        },
    }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
}

.avatar {
    width: 128px;
    height: 128px;
    display: block;
}
</style>

